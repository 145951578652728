.navbutton {
	text-decoration: none;
	list-style: none;
	color: white;
	font-family: sans-serif;
	font-weight: bold;
	padding: 12px 16px;
	margin: 0 8px;
	position: relative;
	cursor: pointer;
	white-space: nowrap;
	border-radius: 25px;
	transition: 0.2s;
}

.navbutton::before {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
	transition: 0.2s;
	border-radius: 25px;
	
}

.navbutton:hover {
	color: black;
}

.navbutton:hover:not(.active)::before {
	background: linear-gradient(to bottom, #e8edec, #d2d1d3);
	box-shadow: 0px 3px 20px 0px black;
	transform: scale(1.1);
}

.active{
	padding: 8px 16px;
	color: black;
	border-radius: 25px;
	background: linear-gradient(to bottom, #e8edec, #d2d1d3);
	box-shadow: 0px 3px 20px 0px black;
	transition: 0.5s;
}
