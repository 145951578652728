.navbar{
    position: relative;
    top: 0;
    left: 10%;
    width: 80%;
    height: 15%;
    z-index: 1;
}

/* #title{
    position: relative;
    width: fit-content;
    left: 5%;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
} */