@font-face {
  font-family: Spartan;
  src: url(../public/fonts/league-spartan.bold.ttf);
}

.app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #23272A;
  color: white;
  text-align: center;
  justify-content: center;
  background: url("../public/img/background.jpg") center/cover no-repeat;
  font-family: Spartan;
}

.body {
  color: white;
}
