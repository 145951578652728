.navmenu {
	border-radius: 25px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: inline-flex;
	background-color: rgba(0, 0, 0, 0.4);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	align-items: center;
	margin: 50px 0 0 0;
}
